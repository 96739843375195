// TODO add i18n-framework (i.e. https://www.i18next.com/)
import dedent from 'dedent-js';

import {
  BotCommand,
  BotCommandViaSlash,
  HistoryLog,
  IntegrationDestination,
  IntegrationError,
  IntegrationSource,
  IntegrationWithId,
  botCommandToDescription,
} from '@hints/types';
import { capitalize } from '@hints/utils';

import {
  learningCenterLink,
  supportLinks,
} from './onboardingResponses/common/links';

const botToSupportLink: Record<string, string> = {
  telegram: 'https://i.hints.so/support',
  discord: 'https://get.hints.so/hints-discord',
  whatsapp: 'https://i.hints.so/support',
  'whatsapp-group': 'https://i.hints.so/support',
};

export const availableServicesForConnection = [
  'telegram',
  'sms',
  'whatsapp',
  // 'email',
  // 'slack',
  // 'whatsapp-groups',
  'google-calendar',
  'trello',
  'jira',
  'clickup',
  'notion',
  'hubspot',
  'pipedrive',
  'obsidian',
];

const commands = [
  BotCommand.connect,
  BotCommand.sum,
  BotCommand.paint,
] as BotCommandViaSlash[];

const getContactLink = (source?: IntegrationSource) =>
  (source && botToSupportLink[source]) || 'https://i.hints.so/support';

// ERROR MESSAGES

export const getTryAgainOrContactUsMessage = (source?: IntegrationSource) =>
  `try again or contact us ${getContactLink(source)}`;

export const getCommonErrorMessage = (source?: IntegrationSource) =>
  `Something went wrong.
Please ${getTryAgainOrContactUsMessage(source)}`;

export const messageForUserFromError = (error: Error, log: HistoryLog) => {
  if (error instanceof IntegrationError) {
    return error.message;
  }
  const tryAgainOrContactUs = getTryAgainOrContactUsMessage(log.source);
  const serviceName = capitalize(log.destination);
  const errorMessages: Record<string, string> = {
    // Notion
    'Notion error: Cannot read property': `Notion properties error. Please try /${BotCommand.help} to refresh Notion setup and ${tryAgainOrContactUs}`,
    'Notion error: Could not find database with ID': `Notion database was not found. Please use /${BotCommand.help} to modify Notion login info and ${tryAgainOrContactUs}`,
    'Content creation Failed. Fix the following: Invalid URL for link.': `Notion didn't accept this URL. Please check for typos and ${tryAgainOrContactUs}`,
    'Notion error: API token is invalid.': `Notion doesn't permit you to make changes. Please use /${BotCommand.help} to modify Notion login info and ${tryAgainOrContactUs}`,
    'Notion error: Insufficient permissions for this endpoint.': `I need additional permission in Notion to process this request.\n\nPlease, go to https://i.hints.so/integrations/output/notion/${log.integrationId} and log in to Notion again.`,
    'Notion error: body failed validation: body.children.length should be': `Your message is to big for Notion. Please reduce it and ${tryAgainOrContactUs}`,

    // Google Calendar
    'Insufficient Permission': `Some permissions are missing. Use /${BotCommand.help}, disconnect and then reconnect Google Calendar, tick all the checkboxes and ${tryAgainOrContactUs}`,
    'Google calendar error: invalid_grant': `Some permissions are missing. Use /${BotCommand.help}, disconnect and then reconnect Google Calendar, tick all the checkboxes and ${tryAgainOrContactUs}`,
    'Start and end times must either both be date or both be dateTime.': `I couldn't understand the duration and time for your event. Please ${tryAgainOrContactUs}`,

    // Apideck
    'Apideck error: Invalid time value': `I couldn't understand the date. Please clarify your request and ${tryAgainOrContactUs}`,

    // Common
    'Request failed with status code 400': `${serviceName} didn't understand this request. Please modify it and ${tryAgainOrContactUs}`,
    'Request failed with status code 401': `${serviceName} asks us for authenticaton. Use /${BotCommand.help} and login again to ${serviceName} and ${tryAgainOrContactUs}`,
    'Request failed with status code 403': `Please use /${BotCommand.help} and login again to ${serviceName} and ${tryAgainOrContactUs}`,
    'Request failed with status code 404': `Can't find this resource. Please modify your flow at ${serviceName} using /${BotCommand.help} and ${tryAgainOrContactUs}`,
    'Request failed with status code 50': `There is a problem on the ${serviceName} server side. We are looking into this. Please ${tryAgainOrContactUs}`,
  };
  let messageForUser = getCommonErrorMessage(log.source);

  // eslint-disable-next-line no-restricted-syntax
  for (const messagePart in errorMessages) {
    if (error.message.includes(messagePart)) {
      messageForUser = errorMessages[messagePart];
      break;
    }
  }

  return messageForUser;
};

const errorMessages = {
  TOO_BIG_MESSAGE:
    'Oops! Message is too long. Please send a shorter one, up to 4000 symbols.',
  TOO_LARGE_FILE: 'The file is too large to upload. Maximum file size is 10MB',
  FAILED_TO_LOAD_FILE: 'Unable to load file. Please, try again',
  VOICE_RECOGNITION_NO_TEXT:
    "Sorry I can't hear anything. Could you speak louder?",
  AUTH_FAILED:
    'Error: An error occurred during authorization with provided token. Please try again or reach out to us via ask@hints.so',
  AUTH_UNSUCCESSFUL:
    'Sorry, you have sent the wrong token. If you have any troubles with authorization, reach out to us via ask@hints.so',
  IMAGE_VARIATION_NO_ACCESS:
    'No access to photo url. Try upload an image again',
  IMAGE_VARIATION_FAILED: 'Failed to generate an image. Try again',
  SLACK_UNAUTHORIZED_USER: 'To use Hints bot, you have to authorize first',
  SLACK_UNAUTHORIZED_USER_BUTTON: 'Authorize in Hints',
  AUTHORIZED_ALREADY: 'You have already authorized.',
  GENERATING_IMAGE_UNSUPPORTED:
    'Sorry, I support painting only for Telegram, Whatsapp and Mobile app',
  GENERATING_IMAGE_NO_PROMPT: `Please, provide prompt after the /${BotCommand.paint} command.`,
  GENERATING_IMAGE_FAILED: 'Failed to generate image, please try again',
  SUMMARIZING_TEXT_FAILED: 'Failed to summarize, please try again',
  COMMAND_NOT_EXISTS: `I do not know this command yet. Use /${BotCommand.help} for details.`,
  NO_USER_PROMPT_AFTER_FLOW_COMMAND: `Please, describe what you want me to do with your integrations after the /${BotCommand.flow} command.`,
  NO_USER_PROMPT_AFTER_SUM_COMMAND: `Please, provide a URL or text after the BotCommand.flow${BotCommand.sum} command.`,
  MODERATION_TRIGGERED: `Sorry, I do not like this tone. Could you rephrase?
    I think you’d better talk with my human colleague here https://calendly.com/hints/hints-setup-talk
    You will get along pretty well!
  `,
};

export const getMultipleGoogleCalendarConnectionError = (userTagName: string) =>
  `Sorry, but you can connect only one Google Calendar. Your integration is available by #${userTagName} tag.`;

export const getUnavailableServiceConnectionError = (
  unavailableService: string,
) =>
  `At the moment ${capitalize(
    unavailableService,
  )} is not supported. Tell us more about your use-case via ask@hints.so or https://i.hints.so/support`;

export const getUnsupportedSourceConnectionError = (source: string) =>
  `Sorry, I don't support ${capitalize(source)} at the moment`;

export const getIntegrationDestinationRecognitionError = () =>
  "Can't detect appropriate integration for your request.";

export const getTooBigPromptError = (source: IntegrationSource) =>
  `Sorry, your message is too big for processing. Please make it shorter and ${getTryAgainOrContactUsMessage(
    source,
  )}`;

// INFO MESSAGES

export const systemMessages = {
  ...errorMessages,
  INTEGRATION_IS_LIVE: dedent`
    Your integration is live!

    If you need any help or have questions, just let me know.
  `,
  SUMMARIZING_TEXT: '🤓 Reading and summarizing...',
  GENERATING_IMAGE: '🎨 Painting...',
  GENERATING_IMAGE_VARIATION: '🎨 Generating variations...',
  LOADING: '💬',
  INTEGRATION_STARTED: 'Got it! Processing...',
  FEEDBACK_SENT: dedent`
    Thank You for your feedback! ♥️
    I've already sent it to my developers.
  `,
  COMMAND_CONNECT: dedent`
    Please specify one of the following service you'd like to connect:
    ${availableServicesForConnection
      .map((service) => `- ${capitalize(service)}`)
      .join('\n')}

    You can learn more about Hints Tools here https://hints.so/solutions-tools
  `,
  NEW_USER_START_NO_DESTINATION: dedent`
    Hey! I'm your AI Assistant that helps with tasks like writing, painting, summarizing, and moves these insights to your Notion, Hubspot, ClickUp, Calendar, Trello, Pipedrive, Obsidian, and Jira.

    Just like ChatGPT, I interact in a conversational manner. I understand voice messages too.

    My commands:
    /connect tool (i.e. calendar) links your tools and connects new integrations
    /sum generates summaries from provided text or URL
    /paint gives access to my image generation capabilities

    Ask me anything!
    Learn more: ${learningCenterLink}
    Support: ${supportLinks.email}
  `,
  ADDED_TO_GROUP_CHAT: {
    FIRST: {
      TELEGRAM:
        'Hey! Impressive group of people here 👋\n' +
        "I'm Hints, your AI Assistant. **I answer questions, summarize, translate, write emails/posts, and integrate with your productivity tools.**\n" +
        '\n' +
        'For example, I can summarize a conversation and send it to Notion!\n' +
        "**Use @ to tag me here, and let's chat.**\n" +
        'You can also DM me!',
      WHATSAPP:
        'Hey! Impressive group of people here 👋\n' +
        "I'm Hints, your AI Assistant. *I answer questions, summarize, translate, write emails/posts, and integrate with your productivity tools.*\n" +
        '\n' +
        'For example, I can summarize a conversation and send it to Notion!\n' +
        "*Use @ to tag me here, and let's chat.*\n" +
        'You can also DM me!',
    },
    SECOND:
      'Thanks for adding me here! Looking forward to chatting with everyone',
  },
};

export const getNewTriggersAnnouncement = (
  integrations: IntegrationWithId[],
) => dedent`
  👋 Hey there! Just wanted to let you know that we've made some updates to the bot to make it even more stable and reliable for you.
  If you want to use an integration, simply add a hashtag at the end

  ${integrations
    .map(
      ({ tagName, destination, destinationName }) =>
        `#${tagName} - ${destination}${
          destinationName ? ` (${destinationName})` : ''
        }`,
    )
    .join('\n')}

  To modify the hashtags type /${BotCommand.help}
`;

export const getHelpMessage = ({
  userId,
  modifyIntegrationsUrl,
  faqUrl,
  supportLink,
  integrations,
}: {
  userId: string;
  modifyIntegrationsUrl: string;
  faqUrl: string;
  supportLink: string;
  integrations?: IntegrationWithId[];
}) => dedent`
  Hi! I'm Hints, your AI Assistant. For efficient chats, use the following language to help me understand you.

  For general assistance, speak to me naturally, as with ChatGPT.

  If an integration stopped working, try re-authorizing at Modify Integrations link.
  ${
    integrations?.length
      ? `\nTo use integrations add hashtag (${integrations
          .map((integration) => `#${integration.tagName}`)
          .join(', ')}) of your flow to the message.\n`
      : ''
  }
  My commands:
  ${commands
    .map((command) => `- /${command}: ${botCommandToDescription[command]}`)
    .join('\n')}
  ${
    modifyIntegrationsUrl
      ? `\nModify integrations: ${modifyIntegrationsUrl}`
      : ''
  }

  Learning Center: ${faqUrl}
  Questions? ${supportLink}

  Your user ID: ${userId}
`;

export const clickupConnectionNote =
  'We recommend connecting the integration with ClickUp on the desktop device.';

export const getConnectDestinationMessage = ({
  destination,
  connectionLink,
  existingHashtag,
}: {
  destination: IntegrationDestination;
  connectionLink: string;
  existingHashtag?: string;
}) => dedent`
  ${
    existingHashtag
      ? `Hey, you're already set up with ${capitalize(
          destination,
        )}. To use it, simply add the #${existingHashtag} in the body of a message. Anyway you can still connect the new integration by the link below`
      : `You can connect your ${capitalize(destination)} account here`
  }
  ${connectionLink}${
  destination === 'clickup' ? `\n\n${clickupConnectionNote}` : ''
}`;

export const getConnectSourceMessage = (
  source: IntegrationSource,
  connectionLink: string,
) =>
  `You can connect ${capitalize(
    source,
  )} by clicking on this link: ${connectionLink}`;
