import { createElement, useRef, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { profileSelector } from '@hints/client';
import { IntegrationDestination, isCrmDestination } from '@hints/types';

import { ampli } from '../../../../ampli';
import { LoaderIcon } from '../components/LoaderIcon';
import { useAppSelector } from '../hooks';
import { useIntegration } from '../hooks/useIntegration';
import { useDestination } from '../hooks/useOutputMetadata';
import { CompletePlaybookStep } from './steps/CompletePlaybookStep';
import { DestinationAuthStep } from './steps/DestinationAuthStep';
import { DestinationSetupStep } from './steps/DestinationSetupStep';
import {
  StepComponent,
  StepComponentProps,
  StepNavigation,
} from './steps/StepComponent';

type StepInfo = {
  component: StepComponent;
  className?: string;
  execBefore?: (
    navigation: StepNavigation,
    direction: 'forward' | 'back',
  ) => Promise<void>;
};

const useStepNavigation = (steps: StepInfo[]): StepNavigation => {
  const [step, _setStep] = useState(0);
  const direction = useRef<'forward' | 'back'>('forward');

  const navigation: StepNavigation = {
    currentStep: step,
    back: undefined,
    next: undefined,
    advance: () => {},
  };

  const setStep = async (nextStep: number) => {
    await steps[nextStep].execBefore?.(navigation, direction.current);
    _setStep(nextStep);
  };

  if (step > 0) {
    navigation.back = () => {
      direction.current = 'back';
      setStep(step > 0 ? step - 1 : step);
    };
  }

  navigation.next = () => {
    direction.current = 'forward';
    setStep(step + 1 < steps.length ? step + 1 : step);
  };

  navigation.advance = () => {
    if (direction.current === 'forward') {
      navigation.next?.();
    } else {
      navigation.back?.();
    }
  };

  return navigation;
};

export const ConnectCRM = () => {
  const user = useAppSelector(profileSelector);

  const { destination } = useParams();
  const metadata = useDestination(destination as IntegrationDestination);
  const [integration, setIntegration] = useIntegration(undefined, {
    sources: ['crmchat'],
    destination: destination as IntegrationDestination,
    tagName: metadata?.defaultTagName ?? '',
    userId: user.id,
    from: 'onboarding',
  });
  const source = integration?.sources?.[0];

  const steps: StepInfo[] = [
    {
      component: DestinationAuthStep,
      execBefore: async (nav, dir) => {
        if (dir === 'back') {
          await metadata?.connector?.disconnect();
        }
      },
    },
    {
      component: DestinationSetupStep,
    },
    {
      component: CompletePlaybookStep,
      className: 'bg-[#D8CEFF] md:bg-[#D8CEFF]',
    },
  ];

  const stepNavigation = useStepNavigation(steps);

  if (metadata === null) {
    return (
      <Navigate
        to={`/connect${
          destination && isCrmDestination(destination) ? '/crm' : ''
        }`}
      />
    );
  }

  if (integration === null) {
    return <LoaderIcon />;
  }

  return (
    <div className="relative isolate px-6 lg:px-8">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
        <div className="flex flex-col items-center">
          <div className="w-full max-w-sm">
            {createElement<StepComponentProps>(
              steps[stepNavigation.currentStep].component,
              {
                navigation: stepNavigation,
                source,
                metadata,
                integration,
                setIntegration,
              },
            )}
          </div>
          {/* <div className="flex mt-8 justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Have questions?{' '}
              <a
                href="https://calendly.com/hints/hints-setup-talk"
                target="_blank"
                rel="noreferrer"
                className="font-semibold text-indigo-600"
                onClick={() => {
                  ampli.integrationSetupOnboardingCallClicked({
                    product: 'crmchat',
                  });
                }}
              >
                <span className="absolute inset-0" aria-hidden="true" />
                Book a call with our AI specialist
                <span aria-hidden="true" className="ml-1">
                  &rarr;
                </span>
              </a>
            </div>
          </div> */}
        </div>
      </div>
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
  );
};
